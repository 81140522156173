body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: normal;
  background-color: #f3f3f3;
  color: #413c51;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./themes/Roboto-Regular.ttf) format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
button,
input {
  font-family: "Roboto";
}
